.intro {
  padding-top: 2%;
  padding-bottom: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #11151c;
}
.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
}

.each-project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid white;
  height: auto;
  width: 40%;
  margin: 4% 2%;
  padding-bottom: 1%;
}
.showcase {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid white;
  height: auto;
  width: 40%;
  margin: 4% 2%;
  padding-bottom: 1%;
}

.details {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  padding: 4%;
  align-self: flex-start;
}
.each-project-container > img {
  align-self: flex-start;
  background-size: cover;
  object-fit: fill;
  width: 100%;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-self: flex-end;
  flex-wrap: wrap;
}

.description {
  font-size: 16px;
}

.title {
  color: #1a936f;
  font-weight: bolder;
}

.button {
  font-family: "Bebas Neue", cursive;
  background-color: white;
  border: none;
  outline: none;
  height: 3vh;
  width: 10vw;
}

.button:hover {
  background-color: #88d498;
  color: #212d40;
}

@media only screen and (max-width: 1200px) {
  .intro {
    padding: 8%;
  }
}
@media only screen and (max-width: 900px) {
  .intro {
    padding: 8%;
  }
  .projects-container {
    width: 100%;
  }
}
@media only screen and (max-width: 850px) {
  .projects-container {
    width: 100%;
  }
  .each-project-container {
    width: 70%;
  }
}
@media only screen and (max-width: 700px) {
  .intro {
    padding: 8%;
  }
  .button {
    height: 3vh;
    width: 15vw;
  }
}
@media only screen and (max-width: 550px) {
  .intro {
    padding: 8%;
  }
  .each-project-container {
    width: 100%;
  }
  .button {
    width: 20vw;
  }
}
@media only screen and (max-width: 350px) {
  .intro {
    padding: 8%;
  }
}
