.about-container {
  padding-top: 2%;
  padding-bottom: 10%;
  background-color: white;
  padding-left: 20vw;
  padding-right: 20vw;
}

.answer {
  color: #212d40;
}
:root {
  --secondary-color: #1a936f50;
}

.question-1 {
  animation: 1.2s slideIn ease-in-out forwards .00001s;
  color: #11151c;
  opacity: 0;
  font-size: 26px;
  font-weight: bolder;
}

.question-1::before {
  content: "";
  width: 0%;
  height: 7px;
  background-color: var(--secondary-color);
  position: absolute;
  bottom: -10px;
  animation: 1s underline ease-in-out both 1s;
  mix-blend-mode: screen;
}
.question-2 {
  animation: 1.2s slideIn ease-in-out forwards .00001s;
  opacity: 0;
  color: #11151c;
  font-size: 26px;
  font-weight: bolder;
}

.question-2::before {
  content: "";
  width: 0%;
  height: 7px;
  background-color: var(--secondary-color);
  position: absolute;
  bottom: -10px;
  animation: 1s underline ease-in-out both 7s;
  mix-blend-mode: screen;
}
.question-3 {
  animation: 1.2s slideIn ease-in-out forwards .00001s;
  opacity: 0;
  color: #11151c;
  font-size: 26px;
  font-weight: bolder;
}

.question-3::before {
  content: "";
  width: 0%;
  height: 7px;
  background-color: var(--secondary-color);
  position: absolute;
  bottom: -10px;
  animation: 1s underline ease-in-out both 12s;
  mix-blend-mode: screen;
}
.question-4 {
  animation: 1.2s slideIn ease-in-out forwards .00001s;
  opacity: 0;
  color: #11151c;
  font-size: 26px;
  font-weight: bolder;
}

.question-4::before {
  content: "";
  width: 0%;
  height: 7px;
  background-color: var(--secondary-color);
  position: absolute;
  bottom: -10px;
  animation: 1s underline ease-in-out both 17s;
  mix-blend-mode: screen;
}

@keyframes underline {
  100% {
    width: 100%;
  }
}

@keyframes slideIn {
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@media only screen and (max-width: 1000px) {
  .about-container {
    padding-top: 8%;
    padding-bottom: 15%;
  }
}

@media only screen and (max-width: 800px) {
  .about-container {
    padding-top: 8%;
    padding-bottom: 20%;
  }
}
@media only screen and (max-width: 700px) {
  .about-container {
    padding-top: 8%;
    padding-bottom: 25%;
  }
}

@media only screen and (max-width: 400px) {
  .about-container {
    padding-top: 8%;
    padding-bottom: 30%;
  }
}
