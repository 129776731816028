.contact {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
.full-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20vh;
  margin-top: 5%;
  padding: 2% 8%;
  height: 70vh;
  width: auto;
  justify-content: space-evenly;
  background-color: #11151c;
}
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60vh;
  justify-content: space-evenly;
}

.email-info {
  font-size: 26px;
  color: #1a936f;
}

.email-info:hover {
  color: #88d498;
}

.float-label-1 label,
.float-label-2 label,
.float-label-3 label {
  font-family: "Arima Madurai", cursive;
  font-size: 14px;
  padding: 0 12px;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 14px) scale(1);
  transition: all 0.2s ease-out;
}

.float-label-1 input,
.float-label-2 input {
  font-family: "Arima Madurai", cursive;
  background-color: #364156;
  outline: 0;
  font-size: 14px;
  width: 40vw;
  padding: 10px 10px 20px 10px;
  margin: none;
  color: white;
  border: none;
}

.float-label-3 textarea {
  font-family: "Arima Madurai", cursive;
  background-color: #364156;
  outline: 0;
  font-size: 14px;
  width: 40vw;
  padding: 10px 10px 100px 10px;
  color: white;
  border: none;
}

.float-label-1 .Active,
.float-label-2 .Active {
  transform: translate(10%, -2vh);
  color: rgba(255, 255, 255, 0.61);
  font-size: 12px;
}
.float-label-3 .Active {
  transform: translate(5%, -2vh);
  color: rgba(255, 255, 255, 0.61);
  font-size: 12px;
}

.submit-button {
  font-family: "Bebas Neue", cursive;
  background-color: white;
  color: #11151c;
  border: none;
  outline: none;
  height: 5vh;
  width: 30vw;
  font-size: 18px;
}
.submit-button:hover {
  background-color: #88d498;
  color: #212d40;
}

@media only screen and (max-width: 800px) {
  .float-label-1 input,
  .float-label-2 input {
    width: 70vw;
    margin-bottom: 5px;
  }
  .float-label-3 textarea {
    margin-bottom: 5px;
    width: 70vw;
  }
  .contact {
    background-color: #11151c;
  }
  .form-container {
    height: 55vh;
  }
  .full-container{
    margin-top: 15%;

  }
}

@media only screen and (max-width: 555px) {
  .full-container {
    height: 65vh;
  }
  .form-container {
    height: 80vh;
  }
}

@media only screen and (max-width: 500px) {
  .full-container {
    height: 70vh;
  }
  .form-container {
    height: 80vh;
  }
}

@media only screen and (max-width: 400px) {
  .float-label-1 input,
  .float-label-2 input {
    padding: 10px 10px 10px 10px;
    margin-bottom: 5px;
  }
  .float-label-3 textarea {
    margin-bottom: 5px;
  }

  .submit-button {
    width: 75vw;
  }
  .email-info {
    font-size: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .email-info {
    font-size: 18px;
  }
  .email {
    overflow: hidden;
  }
}
