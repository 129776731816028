@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@700&display=swap");

.App,
html,
body {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: white;
  font-family: "Arima Madurai", cursive;
  font-size: 20px;
  scroll-behavior: smooth;
}
