.footer {
  display: flex;
  background-color: #11151c;
}

.attribution {
  font-size: 0px;
  color: #11151c;
}

.linkedin {
  display: flex;
  z-index: 10;
  padding-left: 90%; 
  font-size: 40px;
  margin-bottom: 5px;
  margin-top: 5px;

}
.linkedin-icon{
  color: #f1537a;
}

@media only screen and (max-width: 768px) {
  .linkedin {
    font-size: 20px;
    padding: 2% 0% 1% 90%; 
  }
}