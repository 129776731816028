.navbar {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  clear: both;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: #212d40;
  font-family: "Bebas Neue", cursive;
  letter-spacing: 1px;
  z-index: 11;
}
.left-nav {
  padding-left: 3%;
}
a {
  text-decoration: none;
  color: white;
}
a:hover {
  color: white;
}

h1 {
  font-size: 30px;
}
.right-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 73vw;
  padding-right: 40px;
  z-index: 11;
}

.image-logo {
  margin-top: 10vh;
}

.resume {
  font-family: "Bebas Neue", cursive;
  background-color: #212d40;
  border: none;
  outline: none;
  border: 2px rgba(255, 255, 255, 0.653) solid;
  padding: 5px 10px 5px 10px;
  font-size: 24px;
  border-radius: 5px;
  width: fit-content;
}

.linkedin-icon-nav{
  color: #f1537a;
  font-size: 24px;
}

@media only screen and (max-width: 768px) {
  .linkedin {
    font-size: 20px;
    padding: 2% 0% 1% 90%; 
  }
}
@media only screen and (max-width: 1300px) {
  .image-logo {
    margin-top: 5vh;
  }
  h1 {
    font-size: 26px;
  }
  .resume {
    padding: 5px 10px 5px 10px;
    font-size: 20px;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 1250px) {
  .right-nav {
    justify-content: space-around;
    z-index: 3;
  }
  h1 {
    font-size: 22px;
  }
  .resume {
    padding: 5px 10px 5px 10px;
    font-size: 16px;
    border-radius: 5px;
  }
  .image-logo{
    height: 100px;
  }
}

@media only screen and (max-width: 1000px) {
  .nav {
    height: 7vh;
  }
  .right-nav {
    display: flex;
    justify-content: space-between;
    width: 73vw;
    padding-right: 40px;
    z-index: 3;

  }
  h1 {
    font-size: 22px;
  }
  .resume {
    padding: 5px 10px 5px 10px;
    font-size: 16px;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 950px) {
  .right-nav {
    display: flex;
    flex-flow: column nowrap;
    background-color: #212d40;
    position: fixed;
    justify-content: flex-start;
    top: 0;
    right: 0;
    height: 100vh;
    width: 30vw;
    padding: 40px;
    transition: transform 0.3s ease-in-out;
    z-index: 3;
  }

  #open {
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }
  #notopen {
    transform: translateX(0);
  }
  .image-logo {
    height: 80px
  }
}

@media only screen and (max-width: 800px) {
  .image-logo {
    padding-top: 20%;
    height: 70px;
  }
}

@media only screen and (max-width: 700px) {
  .image-logo {
    height: 60px;
    padding-top: 20%
  }
  .right-nav {
    width: 40vw;
  }
}
@media only screen and (max-width: 600px) {
  .right-nav {
    width: 45vw;
    z-index: 3;
  }
  .image-logo {
    height: 40px;
    padding-bottom: 60px
  }
}

@media only screen and (max-width: 500px) {
  .right-nav {
    width: 40vw;
    z-index: 3;

  }
}

@media only screen and (max-width: 400px) {
  .image-logo {
    margin-top: 5vh;
    margin-left: 1.5vw;
  }
  .right-nav {
    width: 50vw;
    z-index: 3;
  }
  .image-logo {
    height: 40px;
    padding-bottom: 38px
  }
}
