.intro-pm {
  padding-top: 2%;
  padding-bottom: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #11151c;
}

.details-pm {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  flex-direction: column;
  justify-content: center;
  justify-content: space-between;
  padding: 4%;
}

.title-pm {
  color: white;
  font-weight: bolder;
  text-align: center;
  font-size: 28px;
}


@media only screen and (max-width: 750px) {
  .intro-pm {
    padding: 8%;
  }
}

